@import "~antd/lib/style/themes/default.less";

// Media queries
@desktop: ~"only screen and (min-width: @{screen-xl-min})";
@small-desktop: ~"only screen and (min-width: @{screen-lg-min}) and (max-width: @{screen-lg-max})";
@tablet: ~"only screen and (min-width: @{screen-md-min}) and (max-width: @{screen-md-max})";
@phone: ~"only screen and (max-width: @{screen-sm-max})";

// Font family
@font-family: "IBM Plex Sans", Helvetica, Arial, sans-serif;
@font-feature-settings-base: normal;
@font-variant-base: "normal";

// Gray
@gray-1: #FAFAFA;
@gray-2: #F2F2F2;
@gray-3: #E6E6E6;
@gray-4: #CCCCCC;
@gray-5: #B3B3B3;
@gray-6: #999999;
@gray-7: #666666;
@gray-8: #4D4D4D;
@gray-9: #333333;
@gray-10: #000000;

// Teal
@teal-1: #E6FCFF;
@teal-2: #CCF9FF;
@teal-3: #99F3FF;
@teal-4: #66EDFF;
@teal-5: #00E1FF;
@teal-6: #00CBE6;
@teal-7: #009EB3;
@teal-8: #007180;
@teal-9: #00444D;
@teal-10: #002D33;

// Turquoise
@turquoise-1: #F2FFFD;
@turquoise-2: #E6FFFB;
@turquoise-3: #CCFFF7;
@turquoise-4: #B3FFF4;
@turquoise-5: #80FFEC;
@turquoise-6: #73E6D4;
@turquoise-7: #66CCBD;
@turquoise-8: #59B3A5;
@turquoise-9: #4D998E;
@turquoise-10: #408076;

// Red
@red-base: #CF1322;
@red-1: color(~`colorPalette("@{red-6}", 1) `);
@red-2: color(~`colorPalette("@{red-6}", 2) `);
@red-3: color(~`colorPalette("@{red-6}", 3) `);
@red-4: color(~`colorPalette("@{red-6}", 4) `);
@red-5: color(~`colorPalette("@{red-6}", 5) `);
@red-6: @red-base;
@red-7: color(~`colorPalette("@{red-6}", 7) `);
@red-8: color(~`colorPalette("@{red-6}", 8) `);
@red-9: color(~`colorPalette("@{red-6}", 9) `);
@red-10: color(~`colorPalette("@{red-6}", 10) `);

// Orange
@orange-base: #FF950C;
@orange-1: color(~`colorPalette("@{orange-6}", 1) `);
@orange-2: color(~`colorPalette("@{orange-6}", 2) `);
@orange-3: color(~`colorPalette("@{orange-6}", 3) `);
@orange-4: color(~`colorPalette("@{orange-6}", 4) `);
@orange-5: color(~`colorPalette("@{orange-6}", 5) `);
@orange-6: @orange-base;
@orange-7: color(~`colorPalette("@{orange-6}", 7) `);
@orange-8: color(~`colorPalette("@{orange-6}", 8) `);
@orange-9: color(~`colorPalette("@{orange-6}", 9) `);
@orange-10: color(~`colorPalette("@{orange-6}", 10) `);

// Yellow
@yellow-base: #F1D754;
@yellow-1: color(~`colorPalette("@{yellow-6}", 1) `);
@yellow-2: color(~`colorPalette("@{yellow-6}", 2) `);
@yellow-3: color(~`colorPalette("@{yellow-6}", 3) `);
@yellow-4: color(~`colorPalette("@{yellow-6}", 4) `);
@yellow-5: color(~`colorPalette("@{yellow-6}", 5) `);
@yellow-6: @yellow-base;
@yellow-7: color(~`colorPalette("@{yellow-6}", 7) `);
@yellow-8: color(~`colorPalette("@{yellow-6}", 8) `);
@yellow-9: color(~`colorPalette("@{yellow-6}", 9) `);
@yellow-10: color(~`colorPalette("@{yellow-6}", 10) `);

// Green
@green-base: #52C41A;
@green-1: color(~`colorPalette("@{green-6}", 1) `);
@green-2: color(~`colorPalette("@{green-6}", 2) `);
@green-3: color(~`colorPalette("@{green-6}", 3) `);
@green-4: color(~`colorPalette("@{green-6}", 4) `);
@green-5: color(~`colorPalette("@{green-6}", 5) `);
@green-6: @green-base;
@green-7: color(~`colorPalette("@{green-6}", 7) `);
@green-8: color(~`colorPalette("@{green-6}", 8) `);
@green-9: color(~`colorPalette("@{green-6}", 9) `);
@green-10: color(~`colorPalette("@{green-6}", 10) `);

// Color overrides
@primary-color: @teal-8;
@text-color: @gray-10;
@body-background: @gray-1;

// Primaries
@primary-1: @turquoise-1;
@primary-2: @turquoise-2;
@primary-3: @turquoise-3;
@primary-4: @turquoise-4;
@primary-5: @turquoise-5;
@primary-6: @turquoise-6;
@primary-7: @turquoise-7;
@primary-8: @turquoise-8;
@primary-9: @turquoise-9;
@primary-10: @turquoise-10;

// Primary hover and active
@primary-color-hover: @teal-9;
@primary-color-active: @teal-10;

// Secondary hover and active
@secondary-color-hover: @turquoise-7;
@secondary-color-active: @turquoise-8;

// Tertiary hover and active
@tertiary-color-hover: @teal-8;
@tertiary-color-active: @teal-9;

// Gradients
@gray-gradient: linear-gradient(96.42deg, @gray-6 -14.46%, @gray-7 43.64%, @gray-8 115.63%);
@teal-gradient: linear-gradient(96.8deg, @teal-5 -11.45%, @teal-6 46.68%, @teal-7 100%);
@turquoise-gradient: linear-gradient(96.8deg, @turquoise-5 -11.45%, @turquoise-6 46.68%, @turquoise-7 100%);

// Text sizes
@font-size-base: 16px;
@font-size-xs: 10px;
@font-size-sm: 12px;
@font-size-md: 14px;
@font-size-lg: 16px;
@font-size-xl: 20px;
@font-size-xxl: 24px;

// Header sizes
@heading-1-size: 48px;
@heading-2-size: 36px;
@heading-3-size: 32px;
@heading-4-size: 28px;
@heading-5-size: 24px;
@heading-6-size: 20px;

// Spacing
@spacing-xss: 2px;
@spacing-xs: 4px;
@spacing-sm: 8px;
@spacing-md: 16px;
@spacing-lg: 24px;
@spacing-xl: 32px;
@spacing-xxl: 48px;

// Padding
@padding-xss: @spacing-xss;
@padding-xs: @spacing-xs;
@padding-sm: @spacing-sm;
@padding-md: @spacing-md;
@padding-lg: @spacing-lg;
@padding-xl: @spacing-xl;
@padding-xxl: @spacing-xxl;

// Margin
@margin-xs: @spacing-xs;
@margin-sm: @spacing-sm;
@margin-md: @spacing-md;
@margin-lg: @spacing-lg;
@margin-xl: @spacing-xl;
@margin-xxl: @spacing-xxl;

// Border radius
@border-radius-xs: 1px;
@border-radius-sm: 2px;
@border-radius-md: 4px;
@border-radius-lg: 8px;
@border-radius-xl: 12px;
@border-radius-xxl: 24px;

// Layout
@layout-body-background: @gray-1;
@layout-header-background: @teal-9;
@layout-header-color: @turquoise-6;
@layout-header-height: 64px;
@layout-header-padding: 0 @padding-md;

// Breadcrumbs
@breadcrumb-base-color: @turquoise-6;
@breadcrumb-last-item-color: @turquoise-6;
@breadcrumb-font-size: @font-size-xl;
@breadcrumb-icon-font-size: @font-size-md;
@breadcrumb-link-color: @turquoise-6;
@breadcrumb-link-color-hover: @turquoise-7;
@breadcrumb-separator-color: @turquoise-6;
@breadcrumb-separator-margin: 0 @padding-md;

// Menu
@menu-collapsed-width: 64px;
@menu-item-height: 45px;
@menu-item-color: @gray-7;
@menu-highlight-color: @teal-8;
@menu-item-active-bg: @turquoise-2;
@menu-item-active-border-width: 0;
@menu-item-padding-horizontal: @padding-md;
@menu-icon-size: @font-size-xl;
@menu-icon-size-lg: @font-size-xl;

// Tabs
@tabs-highlight-color: @teal-8;
@tabs-active-color: @teal-8;
@tabs-hover-color: @teal-5;

// Buttons
@btn-default-color: @teal-8;
@btn-font-size-sm: @font-size-md;

// Link
@link-color: @teal-8;
@link-hover-color: @teal-6;
@link-active-color: @teal-7;

// Form
@input-color: @teal-9;
@form-item-label-font-weight: 500;

// Dropdown
@dropdown-selected-color: @teal-9;

// Aqua TODO remove
@aqua-base: #068E99;
@aqua-1: color(~`colorPalette("@{aqua-6}", 1) `);
@aqua-2: color(~`colorPalette("@{aqua-6}", 2) `);
@aqua-3: color(~`colorPalette("@{aqua-6}", 3) `);
@aqua-4: color(~`colorPalette("@{aqua-6}", 4) `);
@aqua-5: color(~`colorPalette("@{aqua-6}", 5) `);
@aqua-6: @aqua-base;
@aqua-7: color(~`colorPalette("@{aqua-6}", 7) `);
@aqua-8: color(~`colorPalette("@{aqua-6}", 8) `);
@aqua-9: color(~`colorPalette("@{aqua-6}", 9) `);
@aqua-10: color(~`colorPalette("@{aqua-6}", 10) `);

// TODO - remove
@connecterra-teal: #068E99;
@coffee: #dfdad7;
@background-1: #f5f2f0;
@background-2: #3c3c3c;
@background-3: #000c17;

// Overrides
@layout-trigger-background: @blue-7;
@menu-item-active-bg: @background-1;
@popover-bg: @background-2;
@popover-color: @white;
@info-color: @blue-7;

// App-specific
@mobile-header-height: @layout-header-height;
@mobile-footer-height: 64px;
@header-icon-size: 24px;
@graph-height-md: 300px;
@graph-overflow-md: -55px;
@graph-padding-correction: 1.25rem;
@graph-visible-height-md: calc(@graph-height-md + @graph-overflow-md - @graph-padding-correction);
@graph-custom-height: 280px;

// Game plans
@game-plan-list-item-padding: @padding-sm @padding-md;
@game-plan-list-empty-height: 17rem;
@game-plan-task-list-icon-size: @font-size-base + 6px;
@game-plan-task-list-empty-height: @game-plan-list-empty-height;
@game-plan-task-sub-font-size: @font-size-sm;
@game-plan-task-checkbox-zoom: 1.35;

// Auth Icons
@auth-ida-icon-size: @font-size-base + 34px;
@auth-connecterra-icon-size: @font-size-base + 12px;

// Popover
@popover-width-md: 200px;
@popover-width-lg: 350px;

// Insights
@insight-list-empty-height: 17rem;
@insight-list-sub-font-size: @font-size-sm;
@insight-header-sub-font-size: @font-size-sm;

// Farm timeline
@farm-timeline-empty-height: 17rem;

// Arbitrary values
@panel-header-height: 115px;
@panel-content-min-height: 280px;

// Collapse
@collapse-blue-background: #E6F7FF;
@collapse-blue-border: #91D5FF;