#dimensions() {
  /**
   * Set the same height and width to the given selector.
   * @param {number} @wh
   */
  .square-dimensions(@wh) {
    width: @wh;
    height: @wh;
  }
}

#border() {
  /**
   * Set the base border settings for all sides of the target.
   * @param {"all"} @side
   */
  .base(@side, @radius: 0) when (@side = all) {
    border: @border-width-base @border-style-base @border-color-base;
    border-radius: @radius;
  }

  /**
   * Set the base border settings for one side of the target.
   * @side {"top"|"right"|"left"|"bottom"} @side
   */
  .base(@side, @radius: 0) when not (@side = all) {
    border-@{side}: @border-width-base @border-style-base @border-color-base;
    border-radius: @radius;
  }

  /**
   * Create a colored border for the targeted component.
   * @param {Color} @color
   */
  .with-color(@color: @border-color-base, @side: all, @rest...) {
    #border.base(@side, @rest);
    border-color: @color;
  }

  /**
   * Clear all borders
   */
  .clear {
    border: 0;
    border-radius: 0;
  }

  /**
   * Clear the border for the given side
   * @side {"top"|"right"|"left"|"bottom"} @side
   */
  .clear(@side) {
    border-@{side}: 0;
  }
}

#icon() {
  .defaultColor {
    :global {
      svg {
        fill: @gray-9;
      }
    }
  }
  .secondaryColor {
    :global {
      path {
        fill: @gray-7;
      }
    }
  }
  .empty {
    :global {
      .ant-empty-image {
        svg {
          fill: @cyan-7;
          width: 100px;

          .gray {
            fill: @gray-6;
          }
        }
      }
    }
  }
}

#question() {
  .Link {
    cursor: pointer;
  }

  .Wrapper {
    margin-bottom: @margin-xs;
  }

  .Primary {
    color: @connecterra-teal;
    display: block;
    font-size: @heading-5-size;
  }

  .Secondary {
    display: block;
    color: @text-color;
    font-size: @font-size-base;
    line-height: 140%;
  }
}

#steps() {
  .Smile {
    :global {
      svg {
        fill: @connecterra-teal;
      }
    }
  }

  .Steps {
    width: 100%;

    :global {
      .ant-steps-item {
        padding: @padding-xs 0;
        width: 100%;
      }

      .ant-steps-item-active {
        background-color: transparent;
      }

      .ant-steps-item-title {
        width: 100%;
      }

      .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail {
        display: block;
      }

      .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: @connecterra-teal;
      }

      .ant-steps-icon {
        circle {
          r: 2px;
          fill: @connecterra-teal;
        }
      }
    }
  }
}