@import 'colorPalette';

// color palettes
@blue-base: #1890ff;
@blue-1: color(~`colorPalette('@{blue-6}', 1) `);
@blue-2: color(~`colorPalette('@{blue-6}', 2) `);
@blue-3: color(~`colorPalette('@{blue-6}', 3) `);
@blue-4: color(~`colorPalette('@{blue-6}', 4) `);
@blue-5: color(~`colorPalette('@{blue-6}', 5) `);
@blue-6: @blue-base;
@blue-7: color(~`colorPalette('@{blue-6}', 7) `);
@blue-8: color(~`colorPalette('@{blue-6}', 8) `);
@blue-9: color(~`colorPalette('@{blue-6}', 9) `);
@blue-10: color(~`colorPalette('@{blue-6}', 10) `);

@purple-base: #722ed1;
@purple-1: color(~`colorPalette('@{purple-6}', 1) `);
@purple-2: color(~`colorPalette('@{purple-6}', 2) `);
@purple-3: color(~`colorPalette('@{purple-6}', 3) `);
@purple-4: color(~`colorPalette('@{purple-6}', 4) `);
@purple-5: color(~`colorPalette('@{purple-6}', 5) `);
@purple-6: @purple-base;
@purple-7: color(~`colorPalette('@{purple-6}', 7) `);
@purple-8: color(~`colorPalette('@{purple-6}', 8) `);
@purple-9: color(~`colorPalette('@{purple-6}', 9) `);
@purple-10: color(~`colorPalette('@{purple-6}', 10) `);

@cyan-base: #13c2c2;
@cyan-1: color(~`colorPalette('@{cyan-6}', 1) `);
@cyan-2: color(~`colorPalette('@{cyan-6}', 2) `);
@cyan-3: color(~`colorPalette('@{cyan-6}', 3) `);
@cyan-4: color(~`colorPalette('@{cyan-6}', 4) `);
@cyan-5: color(~`colorPalette('@{cyan-6}', 5) `);
@cyan-6: @cyan-base;
@cyan-7: color(~`colorPalette('@{cyan-6}', 7) `);
@cyan-8: color(~`colorPalette('@{cyan-6}', 8) `);
@cyan-9: color(~`colorPalette('@{cyan-6}', 9) `);
@cyan-10: color(~`colorPalette('@{cyan-6}', 10) `);

@green-base: #52c41a;
@green-1: color(~`colorPalette('@{green-6}', 1) `);
@green-2: color(~`colorPalette('@{green-6}', 2) `);
@green-3: color(~`colorPalette('@{green-6}', 3) `);
@green-4: color(~`colorPalette('@{green-6}', 4) `);
@green-5: color(~`colorPalette('@{green-6}', 5) `);
@green-6: @green-base;
@green-7: color(~`colorPalette('@{green-6}', 7) `);
@green-8: color(~`colorPalette('@{green-6}', 8) `);
@green-9: color(~`colorPalette('@{green-6}', 9) `);
@green-10: color(~`colorPalette('@{green-6}', 10) `);

@magenta-base: #eb2f96;
@magenta-1: color(~`colorPalette('@{magenta-6}', 1) `);
@magenta-2: color(~`colorPalette('@{magenta-6}', 2) `);
@magenta-3: color(~`colorPalette('@{magenta-6}', 3) `);
@magenta-4: color(~`colorPalette('@{magenta-6}', 4) `);
@magenta-5: color(~`colorPalette('@{magenta-6}', 5) `);
@magenta-6: @magenta-base;
@magenta-7: color(~`colorPalette('@{magenta-6}', 7) `);
@magenta-8: color(~`colorPalette('@{magenta-6}', 8) `);
@magenta-9: color(~`colorPalette('@{magenta-6}', 9) `);
@magenta-10: color(~`colorPalette('@{magenta-6}', 10) `);

// alias of magenta
@pink-base: #eb2f96;
@pink-1: color(~`colorPalette('@{pink-6}', 1) `);
@pink-2: color(~`colorPalette('@{pink-6}', 2) `);
@pink-3: color(~`colorPalette('@{pink-6}', 3) `);
@pink-4: color(~`colorPalette('@{pink-6}', 4) `);
@pink-5: color(~`colorPalette('@{pink-6}', 5) `);
@pink-6: @pink-base;
@pink-7: color(~`colorPalette('@{pink-6}', 7) `);
@pink-8: color(~`colorPalette('@{pink-6}', 8) `);
@pink-9: color(~`colorPalette('@{pink-6}', 9) `);
@pink-10: color(~`colorPalette('@{pink-6}', 10) `);

@red-base: #f5222d;
@red-1: color(~`colorPalette('@{red-6}', 1) `);
@red-2: color(~`colorPalette('@{red-6}', 2) `);
@red-3: color(~`colorPalette('@{red-6}', 3) `);
@red-4: color(~`colorPalette('@{red-6}', 4) `);
@red-5: color(~`colorPalette('@{red-6}', 5) `);
@red-6: @red-base;
@red-7: color(~`colorPalette('@{red-6}', 7) `);
@red-8: color(~`colorPalette('@{red-6}', 8) `);
@red-9: color(~`colorPalette('@{red-6}', 9) `);
@red-10: color(~`colorPalette('@{red-6}', 10) `);

@orange-base: #fa8c16;
@orange-1: color(~`colorPalette('@{orange-6}', 1) `);
@orange-2: color(~`colorPalette('@{orange-6}', 2) `);
@orange-3: color(~`colorPalette('@{orange-6}', 3) `);
@orange-4: color(~`colorPalette('@{orange-6}', 4) `);
@orange-5: color(~`colorPalette('@{orange-6}', 5) `);
@orange-6: @orange-base;
@orange-7: color(~`colorPalette('@{orange-6}', 7) `);
@orange-8: color(~`colorPalette('@{orange-6}', 8) `);
@orange-9: color(~`colorPalette('@{orange-6}', 9) `);
@orange-10: color(~`colorPalette('@{orange-6}', 10) `);

@yellow-base: #fadb14;
@yellow-1: color(~`colorPalette('@{yellow-6}', 1) `);
@yellow-2: color(~`colorPalette('@{yellow-6}', 2) `);
@yellow-3: color(~`colorPalette('@{yellow-6}', 3) `);
@yellow-4: color(~`colorPalette('@{yellow-6}', 4) `);
@yellow-5: color(~`colorPalette('@{yellow-6}', 5) `);
@yellow-6: @yellow-base;
@yellow-7: color(~`colorPalette('@{yellow-6}', 7) `);
@yellow-8: color(~`colorPalette('@{yellow-6}', 8) `);
@yellow-9: color(~`colorPalette('@{yellow-6}', 9) `);
@yellow-10: color(~`colorPalette('@{yellow-6}', 10) `);

@volcano-base: #fa541c;
@volcano-1: color(~`colorPalette('@{volcano-6}', 1) `);
@volcano-2: color(~`colorPalette('@{volcano-6}', 2) `);
@volcano-3: color(~`colorPalette('@{volcano-6}', 3) `);
@volcano-4: color(~`colorPalette('@{volcano-6}', 4) `);
@volcano-5: color(~`colorPalette('@{volcano-6}', 5) `);
@volcano-6: @volcano-base;
@volcano-7: color(~`colorPalette('@{volcano-6}', 7) `);
@volcano-8: color(~`colorPalette('@{volcano-6}', 8) `);
@volcano-9: color(~`colorPalette('@{volcano-6}', 9) `);
@volcano-10: color(~`colorPalette('@{volcano-6}', 10) `);

@geekblue-base: #2f54eb;
@geekblue-1: color(~`colorPalette('@{geekblue-6}', 1) `);
@geekblue-2: color(~`colorPalette('@{geekblue-6}', 2) `);
@geekblue-3: color(~`colorPalette('@{geekblue-6}', 3) `);
@geekblue-4: color(~`colorPalette('@{geekblue-6}', 4) `);
@geekblue-5: color(~`colorPalette('@{geekblue-6}', 5) `);
@geekblue-6: @geekblue-base;
@geekblue-7: color(~`colorPalette('@{geekblue-6}', 7) `);
@geekblue-8: color(~`colorPalette('@{geekblue-6}', 8) `);
@geekblue-9: color(~`colorPalette('@{geekblue-6}', 9) `);
@geekblue-10: color(~`colorPalette('@{geekblue-6}', 10) `);

@lime-base: #a0d911;
@lime-1: color(~`colorPalette('@{lime-6}', 1) `);
@lime-2: color(~`colorPalette('@{lime-6}', 2) `);
@lime-3: color(~`colorPalette('@{lime-6}', 3) `);
@lime-4: color(~`colorPalette('@{lime-6}', 4) `);
@lime-5: color(~`colorPalette('@{lime-6}', 5) `);
@lime-6: @lime-base;
@lime-7: color(~`colorPalette('@{lime-6}', 7) `);
@lime-8: color(~`colorPalette('@{lime-6}', 8) `);
@lime-9: color(~`colorPalette('@{lime-6}', 9) `);
@lime-10: color(~`colorPalette('@{lime-6}', 10) `);

@gold-base: #faad14;
@gold-1: color(~`colorPalette('@{gold-6}', 1) `);
@gold-2: color(~`colorPalette('@{gold-6}', 2) `);
@gold-3: color(~`colorPalette('@{gold-6}', 3) `);
@gold-4: color(~`colorPalette('@{gold-6}', 4) `);
@gold-5: color(~`colorPalette('@{gold-6}', 5) `);
@gold-6: @gold-base;
@gold-7: color(~`colorPalette('@{gold-6}', 7) `);
@gold-8: color(~`colorPalette('@{gold-6}', 8) `);
@gold-9: color(~`colorPalette('@{gold-6}', 9) `);
@gold-10: color(~`colorPalette('@{gold-6}', 10) `);

@preset-colors: pink, magenta, red, volcano, orange, yellow, gold, cyan, lime, green, blue, geekblue,
  purple;
